function drawPowerProfileChart() {
    const xAxisValues = powerProfileData.xAxis;
    const profileDurations = powerProfileData.profileDurations;
    const thresholds = powerProfileData.thresholds;

    d3.select("#power-profile-chart").select("svg").remove();

    var container = document.getElementById('power-profile-chart');

    const margin = {top: 10, right: 10, bottom: 30, left: 55};

    var width = parseInt(container.clientWidth);

    var height = parseInt(0.5 * width);

    if (width < 740) {
        height = parseInt(0.7 * width);
    }

    const baseData = profileDurations[0].profileDuration;

    const bisect = d3.bisector(d => d.interval).center;

    var svg = d3.select(container)
        .append("svg")
            .attr("id", "power-profile-chart-svg")
            .attr("width", width)
            .attr("height", height)
            .attr("viewBox", [0, 0, width, height])
            .attr("preserveAspectRatio", "xMaxYMax meet")
            .append("g")
                .attr("transform", `translate(${margin.left}, ${margin.top})`)
            .on("touchstart", event => event.preventDefault());

    // #region Y Axis
    const yScale = d3.scaleLinear()
        .domain([0, d3.max(baseData, d => d.value) + 50])
        .range([height - margin.bottom, 0]);
    
    const y = d3.axisLeft()
        .scale(yScale);

    svg.append("g")
        .attr("class", "y-axis")
        .call(y);

    svg.append("text")
        .attr("transform", "rotate(-90)")
        .attr("y", 0)
        .attr("x", -height / 2)
        .attr("dy", "-3.4em") 
        .style("text-anchor", "middle")
        .attr("class", "axis-label")
        .text("Watts");
    // #endregion

    // #region X Axis
    const xScale = d3.scaleSymlog()
        .domain(d3.extent(baseData, d => d.interval))
        .constant(20)
        .range([1, width - margin.left - margin.right - 10]);
    
    const filteredXAxisValues = xAxisValues.filter(item => baseData.map(d => d.interval).includes(item.interval));

    const x = d3.axisBottom()
        .scale(xScale)
        .tickValues(filteredXAxisValues.map(function(i, index) { 
            if (window.innerWidth < 748) {
                return index % 2 === 0 ? i.interval : null;
            } else {
                return i.interval;
            }
        }))
        .tickFormat(function(l) {
            var label = filteredXAxisValues.find(function(i) {
                return i.interval === l; 
            });

            return label ? label.label : l;
        });

    svg.append("g")
        .attr("class", "x-axis")
        .attr("transform", `translate(0, ${height - margin.bottom})`)
        .call(x);
    // #endregion

    // #region zone areas
    function generateDynamicValues(xRange, baseData) {
        const values = [];
        for (let i = xRange[0]; i <= xRange[1]; i++) {
            const index = bisect(baseData, i, 1);
            const value = baseData[index] ? baseData[index].value : 0;
            values.push({ interval: i, value: value });
        }
        return values;
    }

    const dataGroups = [
        {
            color: "#bc39ff",
            values: generateDynamicValues([1, 20], baseData)
        },
        {
            color: "#ff6cd2",
            values: generateDynamicValues([20, 120], baseData)
        },
        {
            color: "#d2193c",
            values: generateDynamicValues([120, 600], baseData)
        },
        {
            color: "#f5664f",
            values: generateDynamicValues([120, 2400], baseData)
        },
        {
            color: "#ff9e3a",
            values: generateDynamicValues([2400, 7200], baseData)
        },
        {
            color: "#f6ff6b",
            values: generateDynamicValues([7200, 14400], baseData)
        },
        {
            color: "#6cff77",
            values: generateDynamicValues([14400, 25200], baseData)
        },
    ];

    const zonesGenerator = d3.area()
        .x(d => xScale(d.interval))
        .y0(height - margin.bottom)
        .y1(d => yScale(d.value));

    const defsZones = svg.append("defs");

    dataGroups.forEach(group => {
        const groupGradient = defsZones.append("linearGradient")
            .attr("id", `${group.color}-gradient`)
            .attr("gradientUnits", "userSpaceOnUse")
            .attr("x1", "0%")
            .attr("x2", "0%")
            .attr("y1", "0%")
            .attr("y2", "100%");
    
        groupGradient.append("stop")
            .attr("offset", "0%")
            .attr("stop-color", group.color)
            .attr("stop-opacity", 0);
    
        groupGradient.append("stop")
            .attr("offset", "100%")
            .attr("stop-color", group.color)
            .attr("stop-opacity", 0.15);
    });

      // Desenha as áreas no gráfico
    dataGroups.forEach(group => {
        svg.append("path", ":first-child")
            .datum(group.values)
            .attr("class", "area")
            .attr("d", zonesGenerator)
            .attr("fill",`url(#${group.color}-gradient)`);
    });
    // #endregion

    //#region Gradiente
    const defs = svg.append("defs");

    defs.append("linearGradient")
        .attr("id", "color-gradient")
        .attr("gradientUnits", "userSpaceOnUse")
        .attr("x1", "0%")
        .attr("x2", "100%")
        .attr("y1", "0%")
        .attr("y2", "0%")
        .selectAll("stop")
        .data([
            {offset: "0%", color: "#bc39ff", opacity: 0.2},
            {offset: "12%", color: "#ff6cd2", opacity: 0.2},
            {offset: "32%", color: "#d2193c", opacity: 0.2},
            {offset: "55%", color: "#f5664f", opacity: 0.2},
            {offset: "70%", color: "#ff9e3a", opacity: 0.2},
            {offset: "87%", color: "#f6ff6b", opacity: 0.2},
            {offset: "100%", color: "#6cff77", opacity: 0.2}
        ])
        .enter()
        .append("stop")
        .attr("offset", d => d.offset)
        .attr("stop-color", d => d.color)
        .attr("stop-opacity", d => d.opacity);

    const areaGenerator = d3.area()
        .x(d => xScale(d.interval))
        .y0(height - margin.bottom)
        .y1(d => yScale(d.value));

    svg.append("path")
        .datum(baseData)
        .attr("class", "area")
        .attr("d", areaGenerator)
        .style("fill", "url(#color-gradient)");
    //#endregion

    // #region Power Profile Line
    const line = d3.line()
        .x(d => xScale(d.interval))
        .y(d => yScale(d.value));

    profileDurations.forEach(function(obj, i) {
        svg.append("path")
            .datum(obj.profileDuration)
            .attr("class", "d3-power-profile line-" + i)
            .attr("d", line)
            .append("title")
            .text(obj.legend);
    });
    // #endregion

    // #region Modeling Line
    profileDurations.forEach(function(obj, i) {
        svg.append("path")
            .datum(obj.modelingPD)
            .attr("class", "d3-power-profile model-line-" + i)
            .attr("d", line)
            .append("title")
            .style("fill", "none")
            .text(obj.legend);
    });
    // #endregion

    const bisectorY = d3.bisector(d => d.value).center;
    const sortedBest = profileDurations[0].profileDuration.slice().sort((a, b) => a.value - b.value);

    // #region CP
    if (thresholds[0]?.thresholds?.eCP[0]?.value) {
        svg.append("g")
            .attr("class", "d3-power-profile thresholds")
            .attr("transform", `translate(0, ${yScale(thresholds[0].thresholds.eCP[0].value)})`)
            .append("line")
            .attr("x1", xScale(sortedBest[bisectorY(sortedBest, thresholds[0].thresholds.eCP[0].value)].interval))
            .attr("x2", width - margin.left - margin.right)
            .style("stroke-width", "1px")
            .attr("stroke-dasharray", 6)

        svg.append("text")
            .attr("class", "d3-power-profile thresholds-legend")
            .attr("text-anchor", "end")
            .attr("transform", `translate(${width - margin.left - margin.right}, ${yScale(thresholds[0].thresholds.eCP[0].value)})`)
            .attr("dy", "-0.5em")
            .text("eCP: " + thresholds[0].thresholds.eCP[0].value);
    }
    // #endregion
    
    // #region MAP
    if (thresholds[0]?.thresholds?.eMAP[0]?.value) {
        svg.append("g")
            .attr("class", "d3-power-profile thresholds")
            .attr("transform", `translate(0, ${yScale(thresholds[0].thresholds.eMAP[0].value)})`)
            .append("line")
            .attr("x1", xScale(sortedBest[bisectorY(sortedBest, thresholds[0].thresholds.eMAP[0].value)].interval))
            .attr("x2", width - margin.left - margin.right)
            .style("stroke-width", "1px")
            .attr("stroke-dasharray", 6)

        svg.append("text")
            .attr("class", "d3-power-profile thresholds-legend")
            .attr("text-anchor", "end")
            .attr("transform", `translate(${width - margin.left - margin.right}, ${yScale(thresholds[0].thresholds.eMAP[0].value)})`)
            .attr("dy", "-0.5em")
            .text("eMAP: " + thresholds[0].thresholds.eMAP[0].value);
    }
    // #endregion

    // #region FTP
    if (thresholds[0]?.thresholds?.eFTP[0]?.value) {
        svg.append("text")
            .attr("class", "d3-power-profile thresholds-legend")
            .attr("text-anchor", "end")
            .attr("transform", `translate(${width - margin.left - margin.right}, ${yScale(thresholds[0].thresholds.eFTP[0].value)})`)
            .attr("dy", "0.8em")
            .text("eFTP: " + thresholds[0].thresholds.eFTP[0].value);
    }
    // #endregion

    // #region VT1
    if (thresholds[0]?.thresholds?.eVT1) {
        svg.append("g")
        .attr("class", "d3-power-profile thresholds")
        .attr("transform", `translate(0, ${yScale(thresholds[0].thresholds.eVT1)})`)
        .append("line")
        .attr("x1", xScale(sortedBest[bisectorY(sortedBest, thresholds[0].thresholds.eVT1)].interval))
        .attr("x2", width - margin.left - margin.right)
        .style("stroke-width", "1px")
        .attr("stroke-dasharray", 6)

    svg.append("text")
        .attr("class", "d3-power-profile thresholds-legend")
        .attr("text-anchor", "end")
        .attr("transform", `translate(${width - margin.left - margin.right}, ${yScale(thresholds[0].thresholds.eVT1)})`)
        .attr("dy", "1em")
        .text("eVT1: " + thresholds[0].thresholds.eVT1);
    }
    // #endregion

    //#region Tooltip
    const mask = svg.append("rect")
        .attr("x", 0)
        .attr("y", 0)
        .attr("width", width)
        .attr("height", height - 30)
        .style("fill", "none")
        .style("pointer-events", "all");
    
    mask.on("pointerenter pointermove", pointerMoved)
        .on("pointerleave", pointerLeft);

    const tooltip = d3.select("#tooltip");

    const tooltipLine = svg.append("line")
        .attr("stroke", "#6E7278")
        .attr("y1", height - margin.bottom)
        .style("display", "none");

    function pointerMoved(event) {
        const i = bisect(baseData, xScale.invert(d3.pointer(event)[0]));
        const tooltipWidth = document.getElementById('tooltip').offsetWidth;

        var left = event.offsetX + 10;

        if (width - event.offsetX < tooltipWidth ) {
            left = width - tooltipWidth;
        }

        tooltipLine.attr("transform", `translate(${xScale(baseData[i].interval)}, 0)`)
            .attr("y2", yScale(baseData[i].value))
            .attr("stroke-dasharray", 3)
            .style("display", null);

        const pointerX = d3.pointer(event)[0];
        const x0 = xScale.invert(pointerX);
        let minDiff = Infinity;
        let closest;

        // Encontre o conjunto de dados mais próximo ao ponto do mouse
        profileDurations.forEach(function(d) {
            d.profileDuration.forEach(function(PD) {
                const diff = Math.abs(PD.interval - x0);
                if (diff < minDiff) {
                    minDiff = diff;
                    closest = PD;
                }
            });
        });

        let modelValue = ``;

        let htmlContent = `<span class="d-flex flex-row justify-content-between text-11 fw-bold">
                            <span>
                                ${baseData[i].label}
                            </span>
                            <span>Modelo</span>
                        </span>`;

        profileDurations.forEach(function(d, index) {
            const info = d.profileDuration[i];

            const model = d.modelingPD[i];

            if (info !== undefined) {
                htmlContent += `
                    <span class="d-flex flex-row justify-content-between gap-2 d3-power-profile power-profile-tooltip line-${index}">
                        <span>
                            <span>
                                <i class="material-symbols-rounded text-warning text-16">bolt</i>
                                ${info.value}W
                            </span>
                            <span>
                                ${d.legend}
                            </span>
                            <span>
                                ${info.performedAt}
                            </span>
                        </span>
                        <span class="fst-italic">
                            <span>${model.value}W</span>
                        </span>
                    </span>
                `;
            }
        });

        tooltip.style("visibility", "visible")
            .style("left", `${left}px`)
            .style("top", 30 + "px")
            .html(htmlContent);
    }

    function pointerLeft(event) {
        const tooltipElement = document.getElementById('tooltip');
        const isHovered = event.relatedTarget === tooltipElement || tooltipElement.contains(event.relatedTarget);

        if (!isHovered) {
            tooltip.style("visibility", "hidden");
            tooltipLine.style("display", "none");
        }
    }
    //#endregion

    return svg.node();
}

let resizeTimer;

d3.select(window).on("resize", function() {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function() {
        drawPowerProfileChart();
    }, 200);
});

drawPowerProfileChart();